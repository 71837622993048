<template>
  <div style="display: grid;">
    <router-link :to="{ name: 'client.details', params: { clientId: task_client.id }}">
      <i class="fas fa-user-circle"></i>
      <strong class="text-black font-scale">
        {{ task_client.name }}
      </strong>
    </router-link>
    <br />
    <span class="text-muted font-medium font-small">
      <i class="fa fa-phone-square text-blue" />
      <a :href="'tel:+' + task_client.phone">
        {{ task_client.phone }}
      </a>
      <a @click="callLogging()" :href="'tel:+' + task_client.phone" class="float-right pointer" style="margin-top: -10px;">
        <i v-if="is_called == 1" class="fa fa-phone-square status-phone" style="color: green" />
        <span v-else="is_called == 0">
          <i class="fa fa-phone-square status-phone" style="color:red" />
          <small class="font-small text-black"
                 v-if="task_type_status_id === 10 && (Math.floor((new Date() - new Date(task_created_at)) / (1000 * 60)) <= 30)">
            <br />
            <strong>{{ (30 - Math.floor(((new Date()) - (new Date(task_created_at))) / (1000 * 60))) }}</strong> мин.
          </small>
          <small class="font-small text-black"
                 v-if="task_type_status_id === 11 && (Math.floor((new Date() - new Date(task_created_at)) / (1000 * 60)) <= 5)">
            <br />
            <strong>{{ (5 - Math.floor(((new Date()) - (new Date(task_created_at))) / (1000 * 60))) }}</strong> мин.
          </small>
        </span>
      </a>

      <span class="float-right">
      </span>
    </span>

    <hr class="mt-1 mb-1" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "task-item-client-block",

  props: {
    task_client: {
      type: Object,
    },
    task_id: {
      type: Number,
    },
    task_created_at: {
      type: String,
    },
    task_type_status_id: {
      type: Number,
    },
    is_called: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  methods: {
    callLogging() {
      console.log(this.task_id);
      API.apiClient.put("/tasks/add_call/" + this.task_id).then(() => {
        this.is_called = 1;
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style scoped>
a:hover {
  opacity: 0.8;
}

.float-right {
  float: right;
}

.pointer {
  cursor: pointer;
}

.status-phone {
  font-size: 38px;
  margin-top: -20px;
  margin-right: 10px;
}
</style>
